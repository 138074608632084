import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import ModelViewer from "../components/ModelViewer"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import Signup from "../components/signup"

const LinksPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Links"
        keywords={[
          `Parametric`,
          `Wall Sculpture`,
          `Contemporary Art`,
          `Dimensional Wall Art`,
          `3D Wall Art`,
          `Modern Art`,
          `Flower Art`,
          `Mosaic Art`,
          `Wood Wall Art`,
          `Sacred Geometry`,
          `Abstract Art`,
          `Wall Object`,
          `3D Printing`,
          `Mandala Art`,
          `Geometric Art`,
          `Trippy Art`,
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">

          <StaticImage src="../images/avatar340.png" alt="Herschel Shapiro" style={{width: "170px", borderRadius: "50%", marginBottom: "25px", marginLeft: "auto", marginRight: "auto", display: "block"}}/>
          
          <div>
            <div style={{marginTop: "2em", marginBottom: "1.5em"}}><Signup/></div>

            {/* <p><a href="https://herschelshapiro.etsy.com" className="button primary fit large">Etsy - Buy Original Wall Sculpture</a></p> */}
            <p><a href="https://www.saatchiart.com/herschelshapiro" className="button primary fit large">Saatchi Art - Buy Original Wall Sculpture</a></p>
            {/* <p><a href="https://herschelshapirostls.etsy.com" className="button primary fit large">Craft & Learn: 3D Printing STL Projects</a></p> */}
            {/* <p><a href="https://thangs.com/designer/herschelshapiro" className="button primary fit large">Thangs - Free STL Digital Models</a></p> */}
            {/* <p><a href="https://patreon.com/HerschelShapiro" className="button primary fit large">Patreon - Exclusive Content</a></p>
            <p><a href="https://discord.gg/3KRUHNp7X8" className="button primary fit large">Discord - Join the Conversation</a></p> */}
            {/* <p><a href="https://www.amazon.com/shop/herschelshapiro" className="button primary fit large">Amazon - Sculpture Care & 3D Printing</a></p> */}
            
            <p><a href="/contact/" className="button primary fit large">Contact</a></p>
            <p><a href="https://herschelshapiro.com/" className="button primary fit large">Portfolio</a></p>
          </div>
          
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "herschel.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1360)
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <LinksPage location={props.location} data={data} {...props} />
    )}
  />
)
